import { useEffect, useState } from 'react';
import './assets/css/styles.css'
import logo from './assets/img/logo.png'
import backIcon from './assets/img/go-back.png'

function App() {

  const [usdSum, setUsdSum] = useState(null)
  const [percOfTotal, setPercOfTotal] = useState(null)

  useEffect(() => {
    fetch('/api-progress-bar')
      .then(response => response.json())
      .then(data => {
        let tmpSumTRX = 0
        if(data?.data?.count > 0){
          for(let i = 0; i < data.data.count; i++){
            tmpSumTRX += Number(data.data.data[i].token_value_in_usd)
          }
        }
        setUsdSum(tmpSumTRX.toFixed(2))
      })
      .catch(error => {
        console.error('error:', error);
      });
  }, []);

  useEffect(() => {
    if(usdSum){
      let tmpPercOfTotal = (usdSum / 15000) * 100
      setPercOfTotal(tmpPercOfTotal)
    }
  }, [usdSum]);


  return (
    <div className="App">
      <div className="container">
        <div className="header">

          <div className="header-content">
            <img src={logo} alt="logo"/>
            <div className="header-text">
              Donation target: XT listing
            </div>
          </div>

          <a href="https://www.coconutchicken.com/">
            <img src={backIcon} alt="www.coconutchicken.com"/>
            GO BACK
          </a>
        </div>
        <div className="content">
          <div className={'progress-bar-content'}>
            <b>What We will get from XT listing:</b>
            <p>Cluck, cluck, cluck!  The $CCC community is ready to crow about our upcoming listing on XT.com, and we need your help to make it happen.</p>
            <p>XT.com is a top-tier exchange with over 600 cryptocurrencies and 12 fiat currencies. It's the perfect platform to take $CCC to the next level and reach a wider audience of investors.</p>
            <p>We've already paid the initial $5k listing fee, and now we need to raise $15k through crowdfunding. This is our chance to show the world the power of the $CCC community and make our token a success.</p>
            <p>How can you help?</p>
            <p>Donate to the marketing wallet: <b>TMmavj5pJNfkyDZ3df1AAFupvNWoTQJofp</b></p>

            {usdSum &&
              <>
                <div className={'nums'}>
                  <span>{usdSum}</span> / 15000 USD
                </div>

                <div className={'progress'}>
                  <div className={'bar'} style={{width: `${percOfTotal}%`}}></div>
                  <div className={'perc'} style={{left: `${percOfTotal}%`}}>{percOfTotal?.toFixed(1)}%</div>
                </div>
              </>
            }

            <b>What We will get from XT listing:</b>

            <div className='ul-columns'>
              <div>
                <ul>
                  <li>1 USDT Pair</li>
                  <li>AMA</li>
                  <li>Twitter Space</li>
                  <li>Banner ads and Mobile pop-ups</li>
                  <li>Inclusion of announcement on email blast with 1.1 Million subs and 30% open rate.</li>
                  <li>Announcement on all our social media multiple times.</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>Push notification to mobile user 900k.</li>
                  <li>Push notification to our TG bot 1.2 mil subs.</li>
                  <li>Staking Pool.</li>
                  <li>Suprise that will be announced after listing on XT</li>
                </ul>
              </div>
            </div>

          </div>
          <div className="footer">
            find us on!<br/>
            <a href="mailto:team@coconutchicken.com">
              <b>team@coconutchicken.com</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
